<template>
  <div>
    <TheFilters
      :filter="filter"
      :loading="loading"
      @exportExcel="exportExcel"
      @clearFilter="clearFilter"
      @refresh="refresh"
      @changeFilter="changeFilter"
      @setFilterMy="setFilterMy"
    />
    <BCard>
      <TheTable
        :re-fetch="reFetch"
      />
    </BCard>

    <b-card>
      <div class="d-flex justify-content-between flex-wrap">
        <div class="d-flex align-items-center mb-0">
          <span class="text-nowrap mr-1">Показать по</span>
          <b-form-select
            v-model="filter.page_size"
            :options="['5', '10', '20']"
            class="mx-1"
            @change="changePerPage"
          />
          <span class="ml-1 text-nowrap"> строк( {{ casesList.count }} )</span>
        </div>
        <div>
          <b-pagination
            v-model="filter.page"
            :total-rows="casesList.count"
            :per-page="filter.page_size"
            first-number
            last-number
            align="right"
            prev-class="prev-item"
            next-class="next-item"
            class="mt-1 mb-0"
            @change="changePage"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { BCard, BFormSelect, BPagination } from 'bootstrap-vue'
import saveFilter from '@/mixins/saveFilter'
import TheFilters from '../components/TheFilters.vue'
import TheTable from '../components/TheTable.vue'

export default {
  components: {
    TheFilters,
    TheTable,
    BCard,
    BPagination,
    BFormSelect,
  },
  mixins: [saveFilter],
  data() {
    return {
      reFetch: true,
      loading: false,
      birthday: {
        start: null,
        end: null,
      },
      payments: {
        start: null,
        end: null,
      },
      last_action: {
        start: null,
        end: null,
      },
      filter: {
        search: null,
        name: null,
        individualEnt: null,
        nameSpi: null,
        GUARANTOR__FULL_NAME: null,
        priority: null,
        CLAIMER_CRM_COMPANY_ID: null,
        delayed: null,
        vip: null,
        region: null,
        DEBT_SUM_FROM: null,
        DEBT_SUM_TO: null,
        bankrupt: null,
        DATE_OF_DEATH: null,
        statusId: null,
        DO_STAGE: null,
        incomeType: null,
        case: null,
        right: null,
        group: null,
        MANAGER: null,
        date: null,
        paymentsDate: null,
        lastPayment: null,
        dateOfDischarge: null,
        overdue: null,
        dateOfSale: null,
        BIRTH_DATE__FROM: null,
        BIRTH_DATE__TO: null,
        PAY_DATE_FROM: null,
        PAY_DATE_TO: null,
        LAST_ACTION_DATE_FROM: null,
        LAST_ACTION_DATE_TO: null,
        BASIS__BELONGING: null,
        page: 1,
        page_size: 20,
      },
    }
  },
  computed: {
    ...mapState('cases', ['casesList']),
  },
  watch: {
    // '$route.query.name': {
    //   handler(val) {
    //     if (val) {
    //       this.filter.search = val
    //     }
    //   },
    //   immediate: true,
    // },
    'datePicker.birthday': {
      handler(val) {
        if (val) {
          this.filter.BIRTH_DATE__FROM = val.start
          this.filter.BIRTH_DATE__TO = val.end
        } else {
          this.filter.BIRTH_DATE__FROM = null
          this.filter.BIRTH_DATE__TO = null
        }
      },
    },
    'datePicker.payments': {
      handler(val) {
        if (val) {
          this.filter.PAY_DATE_FROM = val.start
          this.filter.PAY_DATE_TO = val.end
        } else {
          this.filter.PAY_DATE_FROM = null
          this.filter.PAY_DATE_TO = null
        }
      },
    },
    'datePicker.last_action': {
      handler(val) {
        if (val) {
          this.filter.LAST_ACTION_DATE_FROM = val.start
          this.filter.LAST_ACTION_DATE_TO = val.end
        } else {
          this.filter.LAST_ACTION_DATE_FROM = null
          this.filter.LAST_ACTION_DATE_TO = null
        }
      },
    },
  },

  async mounted() {
    try {
      if (this.$route.query && this.$route.query?.name) {
        this.filter.search = this.$route.query?.name
      } else {
        this.filter.search = null
      }
      await this.refresh(this.filter)
      this.filter = { ...this.$_getFilter() }
      this.filter.page_size = 20
      // await this.refresh({ ...this.filter })
    } catch (error) {
      await this.$_errorToast()
    }
  },
  methods: {
    ...mapActions('cases', ['FETCH_SK_DEBT', 'FETCH_DEBT_EXCEL']),

    setFilterMy() {
      const filter = { ...this.$_getFilter() }

      this.birthday.start = filter.BIRTH_DATE__FROM
      this.birthday.end = filter.BIRTH_DATE__TO
      this.payments.start = filter.PAY_DATE_FROM
      this.payments.end = filter.PAY_DATE_TO
      this.last_action.start = filter.LAST_ACTION_DATE_FROM
      this.last_action.end = filter.LAST_ACTION_DATE_TO
    },

    // eslint-disable-next-line camelcase
    async changePerPage(page_size) {
      try {
        this.reFetch = true
        // eslint-disable-next-line camelcase
        this.filter.page_size = page_size
        this.filter.page = 1
        await this.FETCH_SK_DEBT(this.filter)
      } catch (error) {
        await this.$_errorToast()
      }
      this.reFetch = false
    },

    async changePage(value) {
      try {
        this.reFetch = true
        this.filter.page = value
        await this.FETCH_SK_DEBT(this.filter)
      } catch (e) {
        await this.$_errorToast(e)
      }
      this.reFetch = false
    },

    async exportExcel() {
      try {
        this.loading = true
        await this.FETCH_DEBT_EXCEL(this.filter)
          .then(res => {
            const url = window.URL.createObjectURL(new Blob([res]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', 'file.xls')
            document.body.appendChild(link)
            link.click()
            this.$_okToast()
          })
      } catch (error) {
        await this.$_errorToast()
      }
      this.loading = false
    },

    changeFilter(type, value) {
      this.filter.page = 1

      if (type === 'payments') {
        this.filter.PAY_DATE_FROM = value?.start || ''
        this.filter.PAY_DATE_TO = value?.end || ''
      } else if (type === 'birthday') {
        this.filter.BIRTH_DATE__FROM = value?.start || ''
        this.filter.BIRTH_DATE__TO = value?.end || ''
      } else if (type === 'last_action') {
        this.filter.LAST_ACTION_DATE_FROM = value?.start || ''
        this.filter.LAST_ACTION_DATE_TO = value?.end || ''
      } else {
        this.filter[type] = value
      }

      this.$_saveFilter(this.filter)

      this.refresh()
    },

    clearFilter() {
      sessionStorage.removeItem(this.$route.name)
      this.filter = {
        GUARANTOR__FULL_NAME: null,
        DO_STAGE: null,
        search: null,
        DEBT_SUM_FROM: null,
        DEBT_SUM_TO: null,
        page: 1,
        page_size: 20,
      }
      this.birthday = {
        start: null,
        end: null,
      }
      this.payments = {
        start: null,
        end: null,
      }
      this.last_action = {
        start: null,
        end: null,
      }
    },

    async refresh() {
      const sessionFilter = this.$_getFilter()

      if (sessionFilter) {
        this.filter = { ...sessionFilter }

        this.setFilterMy()
      }

      try {
        this.reFetch = true
        await this.FETCH_SK_DEBT(this.filter)
      } catch (error) {
        await this.$_errorToast()
      }
      this.reFetch = false
    },
  },
}
</script>
