export default {
  fields: [
    {
      key: 'id',
      label: '№',
      sortable: false,
    },
    {
      key: 'MANAGER',
      label: 'Менеджер',
      sortable: false,
      tdClass: 'w_g',
    },
    {
      key: 'BASISDEBT',
      label: 'Сумма долга',
      sortable: false,
    },
    {
      key: 'pay_his',
      label: 'Последние платежи',
      sortable: false,
    },
    {
      key: 'LAST_ACTION_DATE',
      label: 'ДАТА ПОСЛЕДНЕГО ДЕЙСТВИЯ',
      sortable: false,
    },
    {
      key: 'debtors',
      label: 'Должники',
      sortable: false,
    },
    {
      key: 'BORROWER.CITY',
      label: 'Город',
      sortable: false,
    },
    {
      key: 'BELONGING',
      label: 'Принадлежность',
      sortable: false,
    },
    {
      key: 'CLAIMER_COMPANY_NAME',
      label: 'Взыскатель',
      sortable: false,
    },
    {
      key: 'EXECUTIVE_DOC_STATUS',
      label: 'Статус ИД',
      sortable: false,
    },
    {
      key: 'BASIS',
      label: 'Стадия',
      sortable: false,
    },
  ],
}
